import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, SxProps } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { createDish, getDish, updateDish } from '../api/dish';


type FormValues = {
  title: string
  price: number
  description: string
  RestaurantId: number
}; 

type DishFormProps = {
  onChanged: () => void
}

export default function DishForm( props: DishFormProps) {

  const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( id )

  if (!thisId) { setThisId("new") } 
   
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm<FormValues>( )
  const [ data, setData ] = useState<FormValues>({ title: "", price: 0, description: "", RestaurantId: 3 })
  const [ isLoading, setIsLoading ] = useState( id != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    console.log(data)
    setData(data)

    if (thisId == 'new') {


      const storedData = localStorage.getItem('current_restaurant_id');
      const restaurantId = storedData ? parseInt(storedData) : 1;

      console.log (restaurantId)

      createDish( {  ...data, RestaurantId: restaurantId, id: 0} ).then( ( json ) => { 
        setThisId( json.data.id.toString() )
       })
    } else {
      updateDish( { ...data, id: Number(thisId)  } )
    }

  }

  useEffect ( () =>{

    if ( thisId != 'new' ) {
      getDish(thisId!).then((json) => {
        console.log(json);
        setValue( "title", json.data.title )
        setValue( "description", json.data.description )
        setValue( "price", json.data.price )
        // setIsLoading(false)
      }).finally(() => setIsLoading(false));
    }
    
  }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    // setOpen(false);
    navigate ('/web/dishes' )
    props.onChanged()


  };


  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Fragment>

        { !isLoading && 
        
        <Dialog
          sx={sx}    
          open={true}
          onClose={ OnDialogClose} 
        >
          
          <form onSubmit={handleSubmit(onSubmit)}>        

        <DialogTitle>{ id=='new' ? 'Neue Speise' : data.title }</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={6}>                    
              <TextField
              required
              autoFocus
              margin="dense"
              id="title"
              label="Name der Speise"
              type="text"
              fullWidth
              variant="filled"
              {...register("title")}
            /> 
                 
            </Grid>

            <Grid item xs={6}>
                <TextField
                required
                autoFocus
                margin="dense"
                id="price"
                label="Preis"
                type="text"
                fullWidth
                variant="filled"
                {...register("price")}
                />
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                required
                autoFocus
                multiline
                margin="dense"
                id="description"
                label="Beschreibung"
                type="text"
                fullWidth
                variant="filled"
                {...register("description")}
                //rows={4}
                maxRows={4}
                />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit">Speichern</Button>
        </DialogActions>
      </form>
      </Dialog> 
  
        }
    </Fragment>
  );
}

