import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { Divider, Grid, Paper, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
// import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"

import { RestaurantItem, createRestaurant, getRestaurant, updateRestaurant } from '../api/restaurant';
import { MySelectBox, MyTextField } from '../components/form/MyTextField';
import { MyTextField2 } from '../components/form/MyTextField2';

type FormValues = {
  title: string
  city: string
  contact_name: string
  description: string
  siteBaseUrl: string
}; 

type RestaurantFormProps = {
  restaurantId?: string
  // onChanged: () => void
}

export default function SettingsRestaurant( props: RestaurantFormProps) {

  const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  //const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( props.restaurantId || 'new' )

  if (!thisId) { setThisId("new") } 
   
  console.log(thisId)

  const methods = useForm<FormValues>()
  const [ data, setData ] = useState<FormValues>( { title: "", city: "", contact_name: "", description: "", siteBaseUrl: "" })
  const [ isLoading, setIsLoading ] = useState( thisId != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    console.log(data)
    setData(data)

    if (thisId == 'new') {
      createRestaurant( { ...data, id: 0, tenant_id: '', siteBaseUrl: ''  } ).then( ( json ) => { 
        setThisId( json.data.id.toString() )
       })
    } else {
      // updateRestaurant( { ...data, id: Number(thisId) } )
      updateRestaurant( { ...data, id: Number(localStorage.getItem('current_restaurant_id')) } )
    }

  }

  useEffect ( () =>{

    if ( thisId != 'new' ) {
      getRestaurant(thisId!).then((json) => {
        setData(json.data);
        methods.setValue( "title", json.data.title )
        methods.setValue( "description", json.data.description )
        methods.setValue( "city", json.data.city )
        methods.setValue( "contact_name", json.data.contact_name )
        methods.setValue( "siteBaseUrl", json.data.siteBaseUrl )
        // setIsLoading(false)
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false)
    }
    
  }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    // setOpen(false);
    navigate ('/restaurants' )
    // props.onChanged()


  };


  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Paper sx={{ p:3, textAlign: 'left' }} elevation={1}>

       <Typography variant="h2">Angaben zum Restaurant</Typography>

        { !isLoading && 
        
            <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onSubmit)}>        

          <DialogContentText sx={ {mt: 2, mb: 4}}>
          Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={12}>                    
              <MyTextField2
                id="title"
                label="Name des Restaurants"
                name="title"
                required
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                 id="city"
                 label="Stadt / Ort"
                 name="city"                 
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="contact_name"
                label="Kontakt"
                name="contact_name"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="description"
                name="description"
                label="Beschreibung / Kommentar"
                rows={5}
              /> 
            </Grid>

          </Grid>

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button variant='contained' type="submit">Speichern</Button>
        </DialogActions>
      </form>
      </FormProvider>
  
        }
    </Paper>
  );
}

