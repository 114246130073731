import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog, DialogTitle, Divider, Grid, Paper, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
// import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"

import { createRestaurant, getRestaurant, updateRestaurant } from '../../api/restaurant';
import { getContentModule, ContentModuleItem, updateContentModule, contentModuleItemDefault  } from '../../api/ContentModule';
import { MySelectBox, MyTextField } from '../../components/form/MyTextField';
import { MyTextField2 } from '../../components/form/MyTextField2';
import ContentModuleEnumeration3FormFields from './ContentModuleEnumeration3Fields';
import ContentModuleTextWithImageFields from './ContentModuleTextWithImageFields';
import ContentModuleHeaderMainFields from './ContentModuleHeaderMainFields';
import ContentModuleMainNavigationFormFields from './ContentModuleMainNavigationFormFields';

type FormValues = {
  title: string
  city: string
  contact_name: string
  description: string
}; 

type ContentModuleFormProps = {
  // contentModuleId?: string
  // onChanged: () => void
}

export default function ContentModuleForm( props: ContentModuleFormProps) {

  const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( id || 'new' )

  // if (!thisId) { setThisId("new") } 
  
   
  console.log(thisId)

  const [ data, setData ] = useState<ContentModuleItem>(contentModuleItemDefault)
  const methods = useForm<ContentModuleItem>( {defaultValues: { title:"ddd"}} )
  const [ isLoading, setIsLoading ] = useState( thisId != 'new' )
  
  const onSubmit: SubmitHandler<ContentModuleItem> = (data: ContentModuleItem) => {
    console.log(data)
    setData(data)

    if (thisId == 'new') {
    //   createRestaurant( { ...data, id: 0 } ).then( ( json ) => { 
    //     setThisId( json.data.id.toString() )
    //   })
    } else {
      updateContentModule( { ...data, id: Number(thisId) } )
    }

  }


  useEffect ( () =>{

    if ( thisId != 'new' ) {
      getContentModule(thisId!).then((json) => {
        setData(json.data);
        methods.reset( json.data ) // load form fields
        console.log(json.data.ContentModuleType.key)
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false)
    }
    
  }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    // setOpen(false);
    navigate ('/web/website' )
    // props.onChanged()


  };


  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 10
    }
 };



  return (<Fragment>

    { !isLoading && 
    
    <Dialog
      sx={sx}    
      open={true}
      onClose={ OnDialogClose} 
      fullWidth={true}
      maxWidth="md"
    >
      
      
    <DialogTitle>{ id=='new' ? 'Neue Speise' : data.ContentModuleType.name }</DialogTitle>
    <Divider />


    <Paper sx={{ p:3, textAlign: 'left' }} elevation={1}>

       

        { !isLoading && 
        
            <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onSubmit)}>        

          { data.ContentModuleType.key == "ENUMERATION_3" ?
             <ContentModuleEnumeration3FormFields /> : 
            data.ContentModuleType.key == "MAIN_NAVIGATION" ?
             <ContentModuleMainNavigationFormFields /> : 
            data.ContentModuleType.key == "TEXT_WITH_IMAGE" ?
             <ContentModuleTextWithImageFields uuid={data.uuid} /> : 
            data.ContentModuleType.key == "HEADER_MAIN" ?
             <ContentModuleHeaderMainFields /> : <></> } 

          {/* <DialogContentText sx={ {mt: 2, mb: 4}}>
          Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={12}>                    
              <MyTextField2
                id="title"
                label="Titel"
                name="title"
                required
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                 id="attributesJson.subtitle"
                 name="attributesJson.subtitle"                 
                 label="Untertitel"
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.0.title"
                label="Title Spalte 1"
                name="attributesJson.items.0.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.0.text"
                label="Text Spalte 1"
                name="attributesJson.items.0.text"
                rows={3}
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.1.title"
                label="Title Spalte 2"
                name="attributesJson.items.1.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.1.text"
                label="Text Spalte 2"
                name="attributesJson.items.1.text"
                rows={3}
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.2.title"
                label="Title Spalte 3"
                name="attributesJson.items.2.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.2.text"
                label="Text Spalte 3"
                name="attributesJson.items.2.text"
                rows={3}
              /> 
            </Grid>
          </Grid> */}

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button variant='contained' type="submit">Speichern</Button>
        </DialogActions>
      </form>
      </FormProvider>
}
    </Paper>
    </Dialog>
        }
    </Fragment> );
}


