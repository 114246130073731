import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface LessVariableItem { 
  id: number;
  var_name?: string
  var_value?: string
  descrition?: string
  }

export interface LessVariableListResponse {
  data?: LessVariableItem[];  
  meta?: Meta;
}

export interface LessVariablesBatch {
  [key: string]: string
}

export const defaultLessVariableItem = {
  id: 0,
  var_name: "",
  var_value: "",
  descrition: ""
}

export const getLessVariablesList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<LessVariableItem[]>(`/less-variables${ queryString != '' ? '?' + queryString : ""}`);
};

export const getLessVariablesListByRestaurant = ( restaurantTenant: string) => {
  return get<LessVariableItem[]>(`/restaurant/${restaurantTenant}/less-variables`);

};

export const updateLessVariablesListForRestaurant = ( restaurantTenant: string, data: LessVariablesBatch) => {
  return update<LessVariablesBatch>(`/restaurant/${restaurantTenant}/less-variables`, data);

};

export const getLessVariable = (id: string) => {
  return get<LessVariableItem>(`/less-variable/${id}`);
};

export const createLessVariable = ( data:LessVariableItem ) => {
  return create<LessVariableItem>(`/less-variable`, data);
};

export const updateLessVariable = ( data: LessVariableItem) => {
    return update<LessVariableItem>(`/less-variable/${data.id}`, data);
};
