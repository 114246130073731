import { ConsumerProps, Fragment } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { Grid, Typography } from '@mui/material';
import { MySelectBox, MyTextField } from '../../components/form/MyTextField';
import { MyTextField2 } from '../../components/form/MyTextField2';
import { ImageUpload } from '../../components/form/ImageUpload';


interface ContentModuleTextWithImageFieldsProps {
  uuid: string
}

export default function ContentModuleTextWithImageFields(props: ContentModuleTextWithImageFieldsProps) {

  return (<Fragment>

          {/* <Typography variant="h2">Modul: Text mir Bild</Typography> */}

          <DialogContentText sx={ {mt: 0, mb: 4}}>
          Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={12}>                    
              <MyTextField2
                id="attributesJson.title"
                label="Titel"
                name="attributesJson.title"
                required
              /> 
            </Grid>
            <Grid item xs={12}>
                <ImageUpload
                  uuidRef={ props.uuid }
                //  id="attributesJson.image"
                //  name="attributesJson.image"                 
                 label="Bilder"
                />
            </Grid>

            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.content"
                label="Content"
                name="attributesJson.content"
                rows={10}
              /> 
            </Grid>
          </Grid>

    </Fragment> );
}


