// import { StorefrontOutlined, DiningOutlined, LanguageOutlined, SettingsOutlined, PeopleAltOutlined, Apps, LocationOn, EmojiPeople} from "@mui/icons-material";
import { DiningOutlined, Mail, Notifications, AccountCircle, SettingsOutlined, StorefrontOutlined, LanguageOutlined, PeopleAltOutlined, Add, MoreVert } from '@mui/icons-material';
import { Avatar, Badge, Box, Divider, IconButton, List, ListItem, Stack, Tab, Tabs, Toolbar, Tooltip, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from './company-logo.svg';
import './header.css';

import RestaurantSelector from "../RestaurantSelector";

const hasRole = ( role: string ) => {
    return localStorage.getItem('roles')?.includes (role)
}

const hasAnyRole = ( roles: string[] ) => {
    
    for (let index = 0; index < roles.length; index++) {
        const role = roles[index];
        if (hasRole(role)) {
            return true
        }        
    }

    return false
}


function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const sx = {
    padding: '2px 0 2px 6px'
}

const sx_selected = {
    padding: '2px 0 2px 2px',
    borderLeft: 4,
    borderColor: 'orange'
}

interface HorizontalNavItem {
    label: string;
    route: string;
    requiredPermission?: string[];
}

export default function HeaderBar() {

    const [selected, setSelected] = useState(1)    
    const navigate = useNavigate()
    const [value, setValue] = useState(0);
    const [ restaurantId, setRestaurantId ] = useState ( "" )

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(event.currentTarget.getAttribute('data-route') as string)
    };
    
    const items: HorizontalNavItem[] = [
        {label: 'Buchungen', route: 'bookings'},
        {label: 'Web', route: 'web/website'},
        {label: 'Einstellungen', route: 'settings/restaurant'},
        // {label: 'Einstellungen', route: 'settings/restaurant', requiredPermission: ['crypto-assets:list']},
      ] 

    const onRestaurantSelected = ( restaurantId: string) => {
        console.log( 'Restauran selected: ' + restaurantId )
        setRestaurantId( restaurantId )
    }      

    // const select = ( item:NavItem ) => {
    //     setSelected (item.id)
    //     navigate( item.target! )
    // }

    let i = 0


    return (
    
    <Stack direction="column" useFlexGap sx={{bgcolor: '#FFFFFF'}}>
        <Toolbar sx={{borderBottom:1, borderColor: 'RGB(0,0,0,0.12)', justifyContent: "space-between" }}>
            
            <Box justifyContent="flex-start" flexDirection="row" display="flex"   sx={{width: '500px'}}>
                <img src={logo} className="App-logo" alt="logo" />
                <RestaurantSelector onSelected={onRestaurantSelected} />                
            </Box>

            <Box justifyContent="flex-end" flexDirection="row" display="flex">
                
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{mt: '16px', mr: 4}}>               
                    { items.map( ( item: HorizontalNavItem ) => 
                    (!item.requiredPermission ||  hasAnyRole(item.requiredPermission)) ? <Tab sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 300, paddingTop:0}} data-route={item.route} label={item.label} {...a11yProps(i++)} /> : null)}
                </Tabs>
       
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                        <Badge color="error"><Mail /></Badge>
                    </IconButton>
                    <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                        <Badge color="error"><Notifications /></Badge>
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        // onClick={handleProfileMenuOpen}
                        color="inherit"
                        >
                    </IconButton>
                </Box>


          </Box>
          {/* <Search>
            
              <SearchIcon />
            
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}

        </Toolbar>
      </Stack>

)
}