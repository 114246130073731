import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

export interface ImageBlobItem { 
  id: number;
  title?: string
  fileName?: string
  filePath?: string
  uuid?: string
  uuid_ref?: string
  image_blob?: string
  }

export const defaultImageBlobItem = {
    id: 0
} 

export interface ImageBlobListResponse {
  data?: ImageBlobItem[];  
  meta?: Meta;
}

export const getImageBlobsList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<ImageBlobItem[]>(`/image-blobs${ queryString != '' ? '?' + queryString : ""}`);
};

export const getImageBlobsByUuidRef = ( uuid_ref:string ) => {

  return get<ImageBlobItem[]>(`/image-blobs/${uuid_ref}`);
};

export const getImageBlob = (id: string) => {
  return get<ImageBlobItem>(`/image-blob/${id}`);
};

export const createImageBlob = ( data:ImageBlobItem ) => {
  return create<ImageBlobItem>(`/image-blob`, data);
};

export const updateImageBlob = ( data: ImageBlobItem) => {
    return update<ImageBlobItem>(`/image-blob/${data.id}`, data);
};
