import { Add, MoreVert } from '@mui/icons-material';
import { Badge, Box, Button, Dialog, Divider, FormControl, Grid, IconButton, InputLabel, Link, List, ListItem, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDishList, getDishListByRestaurant } from '../api/dish';
import { useNavigate } from 'react-router-dom';
import DishForm from './DishForm';
import { Route, Routes } from "react-router-dom"
import { ContentModuleItem, contentModuleItemDefault, getContentModulesListByRestaurant } from '../api/ContentModule';
import ContentModuleForm from './contentModules/ContentModuleForm';

type WebsiteDishesProps = {
  restaurantTenant : string
}

function WebsiteDishes( props: WebsiteDishesProps) {

    const [ isLoading, setIsLoading] = useState (true)
    const [ listing, setListing] = useState<ContentModuleItem[]>( [contentModuleItemDefault] );    
    const [ version, setVersion ] = useState<number>( 1 )
    const navigate = useNavigate();

    useEffect(() => {

      console.log ('WebsiteDishes useEffect')

      getContentModulesListByRestaurant( props.restaurantTenant )
      .then((json) => {
        setListing(json.data)
      })
      .finally(() => setIsLoading(false));
      
    }, [version, props.restaurantTenant]);

    const editDish = (id: number) => {
      navigate ('/web/website/' + id )
    }
    
    const createDish = () => {
      console.log ('createDish')
      navigate ('new' )
    }
            
    const onDishSaveAndClose = () => {    

      console.log ('onDishSaveAndClose')

      setVersion(version + 1)
    }

    return (
    <Paper sx={{ p:3, textAlign: 'left', width: '100%' }} elevation={1}>
      <Stack direction="column" sx={{width: '100%'}}>

      <Toolbar sx={{ justifyContent: "space-between", paddingLeft: '0px'}}>
        <Typography sx={{ color: 'RGB(1,1,1,0.8)' }} variant="h2">Webseiten-Module</Typography>
        <Button size="small" onClick={() => { createDish() }} variant="contained" startIcon={<Add />}>Erstellen</Button>

      </Toolbar>

      <TableContainer sx={{}}>
        <Table aria-label="simple table" sx={{}}>
          <TableHead>
            <TableRow  sx={{ borderTop: 'none'}}>
              <TableCell>Titel</TableCell>
              <TableCell>Modul-Typ</TableCell>
              {/* <TableCell>Preis</TableCell> */}
              <TableCell sx={{ width: 50 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || listing.map((row) => (
              <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => editDish(row.id)}>
                <TableCell><Link>{row.title}</Link></TableCell>
                <TableCell>{row.ContentModuleType.name}</TableCell>
                {/* <TableCell>{row.price}</TableCell> */}
                <TableCell><IconButton><MoreVert /></IconButton></TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>

      <Routes>
        <Route path=":id" element={<ContentModuleForm />} />
        
      </Routes>

    </Stack>
    </Paper>)
  }

  export default WebsiteDishes