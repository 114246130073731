import { Container, Stack } from '@mui/material';
import { Route, Routes } from "react-router-dom"
import SettingsRestaurant from './SettingsRestaurant';
import { LeftNavigation } from '../components/LeftNavigation';

type NavItem = {
  id: number,
  title?: string,
  target?: string
}

const menuItems = [
  { id: 1, title: "Restaurant", target: 'restaurant'},
  { id: 5, title: "Impressum", target: 'impressum'},
  { id: 7, title: "Subscription", target: 'restaurant-map'},
  { id: 20, title: "Danger Zone", target: 'my-restaurant'},
]

export default function SettingsSection() {
  
  return (   
    <Container maxWidth="lg" sx={{mt: 6}}>
      <Stack direction="row">
        <LeftNavigation menuItems={menuItems} />

        <Routes>
          <Route path="/restaurant" element={ <SettingsRestaurant restaurantId={ localStorage.getItem('tenant') || '' } /> } />
        </Routes>

      </Stack>
    </Container>

)}
