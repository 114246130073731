import { get, create, update } from '../../lib/request'
import { DishItem } from '../api'

export const getDishList = () => {
  return get<DishItem[]>(`/dishes`);
};

export const getDishListByRestaurant = ( restaurantTenant: string) => {
  return get<DishItem[]>(`/restaurant/${restaurantTenant}/dishes`);
};

export const getDish = (id: string) => {
  return get<DishItem>(`/dish/${id}`);
};

export const createDish = ( data:DishItem ) => {
  return create<DishItem>(`/dish`, data);
};

export const updateDish = ( data: DishItem) => {
    return update<DishItem>(`/dish/${data.id}`, data);
};

