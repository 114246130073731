import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';
  
// Restaurant

export interface RestaurantItem {

  id: number;
  title: string;
  city: string;
  description: string;
  siteBaseUrl: string;
  contact_name: string;
  tenant_id?: string;
  
} 

export interface RestaurantListResponse {
  data?: RestaurantItem[];  
  meta?: Meta;
}

export const restaurantItemDefault:RestaurantItem = {
  id:0,
  title:"",
  city: "",
  description : "",
  contact_name : "",
  siteBaseUrl: ""
}

export const getRestaurantList = () => {
  return get<RestaurantItem[]>(`/restaurants`);
};

export const getRestaurant = (id: string) => {
  return get<RestaurantItem>(`/restaurant/${id}`);
};

export const createRestaurant = ( data:RestaurantItem ) => {
  return create<RestaurantItem>(`/restaurant`, data);
};

export const updateRestaurant = ( data: RestaurantItem) => {
    return update<RestaurantItem>(`/restaurant/${data.id}`, data);
};

