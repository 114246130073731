import { StorefrontOutlined, DiningOutlined, LanguageOutlined, SettingsOutlined, PeopleAltOutlined, Apps, LocationOn, EmojiPeople } from "@mui/icons-material";
import { Box, Divider, IconButton, List, ListItem, Stack, Tooltip } from "@mui/material";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";

type NavItem = {
    id: number,
    title?: string,
    target?: string
}

type LeftNavigationProps = {
    menuItems: NavItem[]
}

const sx = {
    padding: '2px 0 2px 6px',
    color: '#6b7185'
}
 
const sx_selected = {
    padding: '2px 0 2px 2px',
    borderLeft: 4,
    borderColor: '#1a7ae6',
    color: '#1a7ae6',
}
  

export const LeftNavigation: React.FC<LeftNavigationProps> = (props) => {

    const [selected, setSelected] = useState(1)    
    const navigate = useNavigate()

    const select = ( item:NavItem ) => {
        setSelected (item.id)
        navigate( item.target! )
    }

    return (
    
    <Stack>
        <List sx={{ width: 285 }}>
        { props.menuItems.map( (item) => (
            <ListItem sx={ selected == item.id ? sx_selected : sx }  onClick={ () => select( item )  }>
                <Box sx={{fontSize: '22px', fontWeight: 300, p: 2  }}>
                    {item.title}
                    {/* </Typography> */}
                    </Box>
                {/* </Tooltip> */}
            </ListItem>
        ))}
        </List>
    </Stack>
)
}