import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface ContentModuleTypeItem { 
  id: number
  name: string
  key: string
}

export interface ContentModuleItem { 
  id: number;
  uuid: string
  orderNumber?: number
  title?: string
  attributesJson?: any
  ContentModuleType: ContentModuleTypeItem
}

export const contentModuleItemDefault:ContentModuleItem = {
  id: 0,
  uuid: "",
  ContentModuleType: {
    id: 0,
    key: "",
    name : ""
  }

}

export interface ContentModuleListResponse {
  data?: ContentModuleItem[];  
  meta?: Meta;
}

export const getContentModulesListByRestaurant = ( restaurantTenant: string) => {
  return get<ContentModuleItem[]>(`/restaurant/${restaurantTenant}/content-modules`);
};

export const getContentModulesList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<ContentModuleItem[]>(`/content-modules${ queryString != '' ? '?' + queryString : ""}`);
};

export const getContentModule = (id: string) => {
  return get<ContentModuleItem>(`/content-module/${id}`);
};

export const createContentModule = ( data:ContentModuleItem ) => {
  return create<ContentModuleItem>(`/content-module`, data);
};

export const updateContentModule = ( data: ContentModuleItem) => {
    return update<ContentModuleItem>(`/content-module/${data.id}`, data);
};
