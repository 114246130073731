import React, { useEffect, useState } from "react"
import './App.css';
import { getRestaurantList } from './api/restaurant'

import Stack from '@mui/material/Stack';
import { Badge, Box, Button, Container, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { DiningOutlined, Mail, Notifications, AccountCircle, SettingsOutlined, StorefrontOutlined, LanguageOutlined, PeopleAltOutlined, Add, MoreVert } from '@mui/icons-material';
import { RestaurantItem } from "./api/restaurant";
import { Route, Routes, useNavigate } from 'react-router-dom';
import RestaurantListing from './pages/RestaurantListing'
import RestaurantForm from "./pages/RestaurantForm";
// import LeftNavigation from "./components/LeftNavigation";
import RestaurantSelector from "./components/RestaurantSelector";
import RestaurantMap from "./pages/RestaurantMap";
import { useAuth0 } from "@auth0/auth0-react";
import { setToken } from "./lib/request/access-token";
import { getMe } from "./api/Me";
import WebsiteBuilder from "./pages/WebsiteBuilder";
import HeaderBar from "./components/HeaderBar";
import SettingsSection from "./pages/SettingsSection";
import WebsiteSection from "./pages/WebsiteSection";

function App() {
  
  const { isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [isUserInfoLoaded, setIsUserInfoLoaded ] = useState ( false )

  const data = { data: [ { id:0, title:"", city: "", description : "", contact_name : "" } ] }
  const [listing, setListing] = useState( data );
  
  const navigate = useNavigate();


  
  useEffect(() => {

    console.log ("Is Authenticated ?")
    if ( isAuthenticated ) {
      getAccessTokenSilently( ).then( (accessToken) => {
        
        console.log ("set access token ?")
        setToken(accessToken)

      getMe( accessToken).then( ( response ) => {
        
        console.log('Response', response)       
        
        if (response.data.permissions!.indexOf("crypto-assets:list") > -1) {
          localStorage.setItem( 'activeRole', 'Compliance Officer' )  
        } else {
          localStorage.setItem( 'activeRole', 'User' )  
        }
        
        localStorage.setItem( 'name', response.data.user )
        localStorage.setItem( 'roles', response.data.permissions!.toString()) 
        
        setIsUserInfoLoaded(true)
      })
  })}}
, [isAuthenticated ])

if (isLoading) {
  return (
    <div className="page-layout">
    </div>
  );
}

if ( !isAuthenticated ) {

  console.log( 'not authenticated')

  loginWithRedirect({
    appState: {
      returnTo: "/auth/organigram",
    },
  });

} else { console.log (user )}


return (isAuthenticated && isUserInfoLoaded) ? (

    <div className="App">
      <HeaderBar/>          
      <Routes>
        <Route path="/settings/*" element={<SettingsSection /> } />            
        <Route path="/web/*" element={<WebsiteSection />} />            
        {/* <Route path="/dishes/*" element={<DishListing restaurantId={restaurantId}/>} />             */}
        <Route path="/my-website/*" element={<WebsiteBuilder />} />            
      </Routes>
    </div>

) : <></>;
    }

export default App;
