import { Container, Stack } from '@mui/material';
import { Route, Routes } from "react-router-dom"
import { LeftNavigation } from '../components/LeftNavigation';
import WebsiteDishes from './WebsiteDishes';
import WebsiteModules from './WebsiteModules';
import WebsiteTheme from './WebsiteTheme';

type NavItem = {
  id: number,
  title?: string,
  target?: string
}

const menuItems = [
  { id: 1, title: "Web Site", target: 'website' },
  { id: 5, title: "Speisekarte", target: 'dishes' },
  { id: 6, title: "Theme", target: 'theme' },
  { id: 7, title: "Impressum", target: 'imprint' },
  { id: 20, title: "Kontaktdaten", target: 'contact-data' },
]

export default function WebsiteSection() {
  
    return (
    
    <Container maxWidth="lg" sx={{mt: 6}}>
      <Stack direction="row">
        <LeftNavigation menuItems={menuItems}  />
        <Routes>
          {/* <Route path="/website" element={ <ContentModuleEnumeration3 /> } /> */}
          <Route path="/website/*" element={ <WebsiteModules restaurantTenant={ localStorage.getItem('tenant') || '' }/> } />
          <Route path="/dishes/*" element={ <WebsiteDishes restaurantTenant={ localStorage.getItem('tenant') || '' }/> } />
          <Route path="/theme/*" element={ <WebsiteTheme restaurantTenant={ localStorage.getItem('tenant') || '' }/> } />
        </Routes>
      </Stack>
    </Container>
)}
