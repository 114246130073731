import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWrapper } from './Auth0ProviderWrapper';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme ({

  // tableRow: {
  //   "&:hover": {
  //     backgroundColor: "blue !important"
  //   }
  // },
  palette: {
    primary: {
      main: "#2968e0"
    }
  },
  typography: {
    fontFamily: ['Graphik Web', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    fontWeightBold: 400,
    h2: {
      fontWeight: 400,
      fontSize: 25

    }
  },

})


root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
        <Auth0ProviderWrapper>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
      </Auth0ProviderWrapper>  
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
