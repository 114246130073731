import { getStorage, setStorage, removeStorage } from '../utils';

const TOKEN_KEY = '_';

let localStorageAvailable = false;
let tokenCookieCache: string | undefined;
try {
    localStorage.getItem('_');
    localStorageAvailable = true;
} catch {}

export const getToken = () => {
    if (localStorageAvailable) {
        return getStorage(TOKEN_KEY);
    } else {
        const pair = document.cookie.split(';').find((e) => e.startsWith(`${TOKEN_KEY}=`));
        return pair ? pair.split('=')[1] : tokenCookieCache;
    }
};

export const getTokenHardCoded = () => {
    return "6242922c-ae5e-4568-a4fe-735fa0269416";
};

export const setToken = ( token: string ) => {
    
    console.log (token);
    if (localStorageAvailable) {
        setStorage(TOKEN_KEY, token);
    } else {
        tokenCookieCache = token;
        document.cookie = `${TOKEN_KEY}=${token}`;
    }
};

export const clearToken = () => {
    if (localStorageAvailable) {
        removeStorage(TOKEN_KEY);
    } else {
        tokenCookieCache = undefined;
        document.cookie = `${TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
};
