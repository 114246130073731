import { buildUrl, unpackResponse } from './utils';
import { getToken, getTokenHardCoded } from './access-token';
import { ServerResponse } from './interface';

export function requestBare<Response, Body = Response>(
    url: string,
    {
        method,
        body,
    }: {
        method: string;
        body?: Body;
    },
) {
   
    const accessToken: string = getToken() as string    
    let tenant: string = localStorage.getItem('tenant') as string

    console.log(tenant)

    return fetch(buildUrl(url), {
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            ...(accessToken) && {Authorization: `Bearer ${accessToken}`},
            ...(tenant) && {'Tenant': `${tenant}`}
        }),
        body: body && JSON.stringify(body),
    });
}

export function request<Response, Body = Response>( url: string, { method, body, }: { method: string; body?: Body;}, ) {
    return requestBare(url, { method, body }).then<ServerResponse<Response>>(unpackResponse);
}

export function get<Response>(url: string) {
    return request<Response>(url, { method: 'GET' });
}

export function create<Response, Body = Response>(url: string, body: Body) {
    return request<Response, Body>(url, { method: 'POST', body });
}

export function update<Response, Body = Response>(url: string, body: Body) {
    return request<Response, Body>(url, { method: 'PUT', body });
}