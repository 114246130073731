import { get } from '../../lib/request'

export interface MeItem { 
  user: string;
  email: string
  permissions?: string[]
}

export const getMe = ( token: string ) => {
  return get<MeItem>(`/auth/me`);
};

