import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog, DialogTitle, Divider, Grid, Paper, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
// import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"

import { createRestaurant, getRestaurant, updateRestaurant } from '../../api/restaurant';
import { getContentModule, ContentModuleItem, updateContentModule  } from '../../api/ContentModule';
import { MySelectBox, MyTextField } from '../../components/form/MyTextField';
import { MyTextField2 } from '../../components/form/MyTextField2';

export default function ContentModuleEnumeration3FormFields() {

  return (<Fragment>

          <DialogContentText sx={ {mt: 2, mb: 4}}>
          Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={12}>                    
              <MyTextField2
                id="attributesJson.title"
                label="Titel"
                name="attributesJson.title"
                required
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                 id="attributesJson.subtitle"
                 name="attributesJson.subtitle"                 
                 label="Untertitel"
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.0.title"
                label="Title Spalte 1"
                name="attributesJson.items.0.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.0.text"
                label="Text Spalte 1"
                name="attributesJson.items.0.text"
                rows={3}
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.1.title"
                label="Title Spalte 2"
                name="attributesJson.items.1.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.1.text"
                label="Text Spalte 2"
                name="attributesJson.items.1.text"
                rows={3}
              /> 
            </Grid>
            <Grid item xs={12}>
                <MyTextField2
                id="attributesJson.items.2.title"
                label="Title Spalte 3"
                name="attributesJson.items.2.title"
                />
            </Grid>
            <Grid item xs={12}>
              <MyTextField2
                id="attributesJson.items.2.text"
                label="Text Spalte 3"
                name="attributesJson.items.2.text"
                rows={3}
              /> 
            </Grid>
          </Grid>

    </Fragment> );
}


