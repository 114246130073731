import { Box, Tabs, Tab, Stack, TextField, Typography, Select, Button, ImageList, ImageListItem, Switch } from "@mui/material"
import { SyntheticEvent } from "react"
import { RefCallBack, useFormContext, Controller } from "react-hook-form"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ImageBlobItem, createImageBlob } from '../../../api/ImageBlob';
import { MuiColorInput } from 'mui-color-input'

interface MyTextFieldProps {
    id: string
    name: string
    label: string
    rows?: number
    required?: boolean
}

export const MyTextField2: React.FC<MyTextFieldProps> = ( props ) => {

    const { register } = useFormContext() // retrieve all hook methods

    return (
        <Stack direction="row" sx={{width: '100%'}}>
            <Typography sx={{mb:1, color: '#686c6e', width:'300px', fontSize: '14px', textAlign: 'left', fontWeight: 600, flexDirection: "row", display: "flex"}} color="black" noWrap>
                <Box>{props.label}</Box> { props.required ? <Box sx={{color: 'red', ml: '3px'}}>*</Box> : <></> }
            </Typography>
            <TextField sx={{width: '100%', mx: 2, bgcolor: '#ecf3fe', borderCollapse: '#ecf3fe !important'}}
                id={props.id}
                hiddenLabel 
                size='small'
                variant="outlined"
                {...register(props.name)}
                {...(props.rows ? { rows: props.rows, multiline: true} : {})}
            />
        </Stack> 

)} 

export const MySwitchField2: React.FC<MyTextFieldProps> = ( props ) => {

    const { register } = useFormContext() // retrieve all hook methods
    
    return (
        <Stack direction="row" sx={{width: '100%'}}>
            <Typography sx={{mb:1, color: '#686c6e', width:'300px', fontSize: '14px', textAlign: 'left', fontWeight: 600, flexDirection: "row", display: "flex"}} color="black" noWrap>
                <Box>{props.label}</Box> { props.required ? <Box sx={{color: 'red', ml: '3px'}}>*</Box> : <></> }
            </Typography>
            <Switch defaultChecked size="small" />
            <Typography >Display table booking button in top navigation</Typography>
            {/* <TextField sx={{width: '100%', mx: 2, bgcolor: '#ecf3fe', borderCollapse: '#ecf3fe !important'}}
                id={props.id}
                hiddenLabel 
                size='small'
                variant="outlined"
                {...register(props.name)}
                {...(props.rows ? { rows: props.rows, multiline: true} : {})}
            /> */}
        </Stack> 

)} 


interface MySelectBoxProps {
    id: string
    name: string
    label: string
    onChange: (e: SyntheticEvent) => void
    onBlur?: (e: SyntheticEvent) => void
}


export const MySelectBox2 = ( props: MySelectBoxProps) => {

    return (
        <Stack direction="row" sx={{width: '100%'}}>
            <Typography sx={{mb:1, color: '#686c6e', fontSize: '14px', textAlign: 'left', fontWeight: 600}} color="black" noWrap>
                {props.label}
            </Typography>
            <Select  size='small' id="filled-basic" variant="outlined"/>
            {/* <Select 
                onChange={props.onChange}
                onBlur={props.onBlur}
                name={props.name}
                hiddenLabel 
                size='small'
                id={props.id}
                variant="outlined"
                sx={{borderBottomLeftRadius: '15px'}}
            /> */}
        </Stack> 

)} 

export const MyColorPicker = ( props: MyTextFieldProps) => {

    const { register } = useFormContext() // retrieve all hook methods

    return (
        <Stack direction="row" sx={{width: '100%'}}>
            <Typography sx={{mb:1, color: '#686c6e', width:'300px', fontSize: '14px', textAlign: 'left', fontWeight: 600, flexDirection: "row", display: "flex"}} color="black" noWrap>
                <Box>{props.label}</Box> { props.required ? <Box sx={{color: 'red', ml: '3px'}}>*</Box> : <></> }
            </Typography>

            <Controller
                name={props.name}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <MuiColorInput 
                    size='small'
                    sx={{ width: '100%', bgcolor: '#ecf3fe', mx:2, p:0 }}
                    format="hex"                    
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    value={value}
                    />
                )}
                />

            
            
        </Stack> 

)} 

