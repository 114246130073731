import { InputLabel, Select, MenuItem, FormControl, SelectChangeEvent } from "@mui/material"
import { RestaurantItem, getRestaurant, getRestaurantList, restaurantItemDefault } from "../../api/restaurant"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type RestaurantSelectorProps = {
    onSelected: ( restaurantId: string ) => void
}

const RestaurantSelector = ( props: RestaurantSelectorProps ) => {
    
    const getRestaurantId = () => {
        const storedData = localStorage.getItem('current_restaurant_id');
        return storedData ? storedData : "1"
    }
    
    // const data:RestaurantItem = restaurantItemDefault
    const [ listing, setListing ] = useState<RestaurantItem[]>( [restaurantItemDefault] );
    const [ selected, setSelected ] = useState ( getRestaurantId() )
    const navigate = useNavigate()

    useEffect(() => {

        console.log ('RestaurantSelector useEffect')

        getRestaurantList()
        .then((json) => {
          // console.log(json);
          setListing(json.data)
        })
        
      }, []);

    const handleRestautantChange  = (event: SelectChangeEvent) => {

      event.preventDefault();

      getRestaurant( event.target.value )
      .then( (json) => {
        localStorage.setItem('tenant', event.target.value);
        localStorage.setItem('current_restaurant_id', json.data.id.toString());
        localStorage.setItem('siteBaseUrl', json.data.siteBaseUrl);
        setSelected(event.target.value)
        props.onSelected( event.target.value );
        navigate('/')
      } ) 

        
        
      }
    
     
    return (
    
    <FormControl size="small" sx={{ marginTop: '3px', ml:2,  pr: 2, minWidth: 120, boxShadow: 0, '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
      <InputLabel sx={{}} id="demo-simple-select-standard-label"></InputLabel>
        <Select
        disableUnderline={true}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selected}
        onChange={handleRestautantChange}
        label="Age"
        variant="standard"


        >
        {listing.map((item) => <MenuItem value={item.tenant_id! }>{item.title + ', ' + item.city}</MenuItem>)}
        </Select>
    </FormControl>
 
    )

}

export default RestaurantSelector