import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { Divider, Grid, Paper, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
// import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"

import { getRestaurant } from '../api/restaurant';
import { MySelectBox, MyTextField } from '../components/form/MyTextField';
import { MyColorPicker, MyTextField2 } from '../components/form/MyTextField2';
import { LessVariableItem, LessVariablesBatch, defaultLessVariableItem, getLessVariablesListByRestaurant, updateLessVariablesListForRestaurant } from '../api/LessVariable';
import { X } from '@mui/icons-material';

type FormValues = {
  [key: string]: string
}; 

type KeyOfFormValues = keyof FormValues

type WebsiteThemeProps = {
  restaurantTenant : string
  // onChanged: () => void
}

export default function SettingsRestaurant( props: WebsiteThemeProps) {

  const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  //const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( props.restaurantTenant || 'new' )

  if (!thisId) { setThisId("new") } 
   
  console.log(thisId)

  const methods = useForm<FormValues>()
  const [ rows, setRows] = useState<LessVariableItem[]>( [defaultLessVariableItem] )
  const [ data, setData ] = useState<FormValues>( { primaryColor: "" })
  const [ isLoading, setIsLoading ] = useState( thisId != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    console.log(data)
    updateLessVariablesListForRestaurant(thisId, data as LessVariablesBatch)
  }

  useEffect ( () =>{

    getLessVariablesListByRestaurant( props.restaurantTenant )
      .then((json) => {
        json.data.forEach( (item ) => {

          //if (item.var_name == "primaryColor" ) methods.setValue( "primaryColor", item.var_value ? item.var_value : "" )
          
          if (item.var_name) {
            methods.setValue( item.var_name, item.var_value ? item.var_value : ""  )
          }  
          
        })
        setRows(json.data)
        

        //methods.setValue( "primaryColor", '#678911' )
        // methods.setValue( "description", json.data.description )
        // methods.setValue( "city", json.data.city )
        // methods.setValue( "contact_name", json.data.contact_name )
        // methods.setValue( "siteBaseUrl", json.data.siteBaseUrl )

       // setIsLoading(false)
      })
      .finally(() => setIsLoading(false));



    // if ( thisId != 'new' ) {
    //   getRestaurant(thisId!).then((json) => {
    //     setData(json.data);

    //     // setIsLoading(false)
    //   }).finally(() => setIsLoading(false));
    // } else {
    //   setIsLoading(false)
    // }
    
  }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    // setOpen(false);
    navigate ('/restaurants' )
    // props.onChanged()


  };


  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Paper sx={{ p:3, textAlign: 'left' }} elevation={1}>

       <Typography variant="h2">Farbschema</Typography>

        { !isLoading && 
        
            <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onSubmit)}  >        

          <DialogContentText sx={ {mt: 2, mb: 4}}>
          Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?
          </DialogContentText>
          
          <Grid container spacing={2}>
          
          { rows.map( (item) => 

            <Grid item xs={12}>     
              <MyColorPicker
              id={item.id.toString()}
              label={item.descrition!}
              name={item.var_name!}
            
              
              />
            </Grid>

          )}

          
          </Grid>

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button variant='contained' type="submit">Speichern</Button>
        </DialogActions>
      </form>
      </FormProvider>
  
        }
    </Paper>
  );
}

