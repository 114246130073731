import React, { useEffect } from "react";
import { Box, Stack, Typography, Select, Button, ImageList, ImageListItem } from "@mui/material"
import { useFormContext } from "react-hook-form"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { defaultImageBlobItem, ImageBlobItem, createImageBlob, getImageBlobsByUuidRef } from '../../../api/ImageBlob';

interface ImageUploadProps {
    uuidRef: string
    label: string
    required?: boolean
}

export const ImageUpload: React.FC<ImageUploadProps> = ( props ) => {

    const { register } = useFormContext() // retrieve all hook methods
    const [ imageBlobs, setImageBlobs ] = React.useState<ImageBlobItem[]>( [defaultImageBlobItem])
    const [ isLoading, setIsLoading ] = React.useState( true  )
    const imageBaseUrl = localStorage.getItem('siteBaseUrl') + '/media/images/'

    useEffect ( () => {

        getImageBlobsByUuidRef(props.uuidRef)
        .then( (json) => {
            setImageBlobs (json.data)
            setIsLoading( false)
        })


    }, [] )


    const convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
              reject(error);
            }
          })
     };

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        const file =  files ? files[0] : null
        
        if (file) {
            
            const base64 = await convertBase64(file) as string
            const image_blob:string = base64.toString().split(',')[1]

            if (base64) {

                const imageBlob: ImageBlobItem = {
                
                    id: 0,
                    image_blob: image_blob,
                    title: 'Hey missy',
                    fileName: file.name,
                    uuid_ref: props.uuidRef
                }

                createImageBlob( imageBlob )
            }
        }
      }
        
    return (
        <Stack direction="row" sx={{width: '100%'}}>
            <Typography sx={{mb:1, color: '#686c6e', width:'300px', fontSize: '14px', textAlign: 'left', fontWeight: 600, flexDirection: "row", display: "flex"}} color="black" noWrap>
                <Box>{props.label}</Box> { props.required ? <Box sx={{color: 'red', ml: '3px'}}>*</Box> : <></> }
            </Typography>            
            <ImageList sx={{  height: 90, width: "100%" }} cols={7} rowHeight={90}>
                <ImageListItem>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={ onChange }
                        />
                    <label htmlFor="raised-button-file">
                        <Button sx={{ height: 90, width:90}} color="info" variant="outlined" component="span" >
                            <CloudUploadIcon sx={{ fontSize: 60}} />
                        </Button>
                    </label>
                </ImageListItem>
                {(!isLoading) && imageBlobs.map((item) => (
                <ImageListItem key={item.id} sx={{width:90}}>
                    <img
                    srcSet={ imageBaseUrl + `${item.fileName}`}
                    src={`${item.fileName}`}
                    alt={item.title}
                    loading="lazy"
                    />
                </ImageListItem>
                ))}
            </ImageList>
        </Stack> 

)} 

