import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { reduceEachLeadingCommentRange } from "typescript";

interface Auth0ProviderWrapperProps {
  children: React.ReactNode;
}

export const Auth0ProviderWrapper = ({
  children,
}: PropsWithChildren<Auth0ProviderWrapperProps>): JSX.Element | null => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  
  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    console.log("Auth0 environment not found")
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"      
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        // scope: 'openid%20profile%20 email'
      }}
      onRedirectCallback={onRedirectCallback}
    >
    {children}

    </Auth0Provider>
  );
};
