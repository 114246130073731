import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Stack, TextField, Toolbar } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { RestaurantTable } from "../components/RestaurantMapItems";
import { getRestaurantTableList } from "../api/restaurantTable";
import { useEffect, useState } from "react";
import { RestaurantTableItem } from "../api/api";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MySelectBox, MyTextField } from "../components/form/MyTextField";


export default function WebsiteBuilder() {

    const [ isLoading, setIsLoading] = useState (true)
    const data = { data: [ { id:0, title:"", capacity: 0, description : "", table_type: "", x_position : 0, y_position : 0, rotation: 0 } ] }    
    const [ restaurantTables, setRestaurantTables] = useState( data );    
    const [ version, setVersion ] = useState<number>( 1 )

    // useEffect(() => {

    //     getRestaurantTableList()
    //     .then((json) => {
    //       console.log(json);
    //       setRestaurantTables(json)
    //     })
    //     .finally(() => setIsLoading(false));
        
    //   }, [version]);


    const onTableSelect = ( tableId: string) => {
        console.log( "Table " + tableId + " selected")
    }

    return (

    <Stack sx={{width: '800px', mt:3}} display="flex" justifyContent="flex-end">


        
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Enumeration 3
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
                                    
            {/* <Grid item xs={6}>                    
              <MySelectBox
            //   required
            //   autoFocus
            //   margin="dense"
              id="title"
              name="title"
              label="Title"
              onChange={()=>{}}
            //   type="text"
            //   fullWidth
            //   variant="outlined"
               {...register("title")}
            /> 
                 
            </Grid>

            <Grid item xs={6}>
                <MyTextField
                label="Sub Title"
                id="subTitle"
                name="subTitle"
                onChange={()=>{}}                
                // required 
                // autoFocus
                // margin="dense"                
                // type="text"
                // fullWidth
                // variant="outlined"
                // {...register("city")}
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField
                label="Column 1 Text"
                id="col1Text"
                name="col1Text"
                onChange={()=>{}}   
                rows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField
                label="Column 2Text"
                id="col2Text"
                name="col2Text"
                onChange={()=>{}}   
                rows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <MyTextField
                label="Column 3 Text"
                id="col3Text"
                name="col3Text"
                onChange={()=>{}}   
                rows={4}
                />
            </Grid> */}

          </Grid>

        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button variant="contained">Save</Button>
        </AccordionActions>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Accordion 2
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Accordion Actions
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion>

        <Box>
        </Box>
    </Stack>)

}